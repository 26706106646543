<template>
	<MainContentComp headerTitle="currencyEncryption">
		<div class="top-header">
			<div class="disal header-left">
				<img :src="headerImg" />
				<div class="ml20">
					<div class="header-left-title">
						Cryptocurrency assets
					</div>
					<!-- <div class="header-left-num">{{ balanceCoin }}<span class="ml5">USDT</span></div> -->
				</div>
			</div>
			<div class="btn-list">
				<el-button class="changeBottom" @click="handleClickBtn('add')">Add wallet</el-button>
				<!-- <el-button
       						@click="handleClickBtn('buy')">{{ translateTitle("买") }}&{{ translateTitle("卖") }}</el-button> -->
				<el-button class="changeBottom" v-if="tableActive == '1'"
					@click="changeTable(2)">Transaction</el-button>
				<el-button class="changeBottom" v-else @click="changeTable(1)">wallet</el-button>
			</div>
		</div>
		<div v-loading="tableLoading" class="list">
			<el-table v-if="tableActive == '1'" class="custom-table flex-table wallet-table" :data="listCoin"
				style="width: 100%">
				<el-table-column prop="balance" label="Tokens" min-width="250" show-overflow-tooltip>
					<template #default="scope">
						<div class="flex">
							<img :src="scope.row.coinLogo" style="margin-right:10px" class="coinLogo" width="35"
								height="35" />
							<div>
								<div class="list-item-title">{{ scope.row.coinName }}</div>
								<div class="disal mobelCopy">
									<el-tooltip :content="scope.row.walletAddress" placement="top">
										<div slot="content">
											<div class="pointer">
												{{ scope.row.walletAddress }}
											</div>
										</div>
										<span v-if="scope.row.walletAddress" class="list-item-num mr20">
											{{ scope.row.walletAddress }}</span>
									</el-tooltip>
									<!-- 	<el-tooltip class="item" effect="dark" placement="top">
												
											</el-tooltip> -->

									<img :src="require('@/assets/imgs/ico_copy@2x.png')" class="cp mr20" width="30"
										@click="onCopy(scope.row.walletAddress)" />
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="coinName" label="Amount" min-width="180" show-overflow-tooltip>
					<template #default="scope">
						<div class="list-item-title">
							{{ scope.row.balance }}
						</div>
						<div class="list-item-num1">{{ scope.row.balance }}<span></span></div>
					</template>
				</el-table-column>
				<el-table-column label="Action" width="280">
					<template #default="scope">
						<el-button style="color: #786234;" @click="handleClickBtn('send', scope.row, 'first')"
							type="text" size="small"><img width="20" src="../../assets/img/ico_jiami_send@2x.png"
								style="margin-right: 5px;">Withdraw</el-button>
						<el-button style="color: #786234;" type="text"
							@click="handleClickBtn('send', scope.row, 'second')" size="small"><img width="20"
								src="../../assets/img/ico_jiami_receive@2x.png"
								style="margin-right: 5px;">Deposit</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-table v-if="tableActive == '2'" stripe class="custom-table flex-table wallet-table" :data="list" style="width: 100%">
				<el-table-column prop="businessId" label="transaction id">
					<template #default="scope">
						<el-tooltip class="item" :content="scope.row.businessId" placement="top">
							<div slot="content">
								<div class="pointer">
									{{ scope.row.businessId }}
								</div>
							</div>
							<span v-if="scope.row.businessId" class="list-item-num mr20">{{ scope.row.businessId |
					mobileFilter }}</span>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column prop="walletAddress" label="sending address" show-overflow-tooltip></el-table-column>
				<el-table-column prop="sourceWalletAddress" label="receiving address"
					show-overflow-tooltip></el-table-column>
				<el-table-column prop="coinName" label="type" show-overflow-tooltip>
					<template #default="scope">
						<div class="">
							<span v-if="scope.row.transactionType == '1'">transfer</span>
							<span v-else-if="scope.row.transactionType == '2'">Buy Coins</span>
							<span v-else-if="scope.row.transactionType == '3'">Sell Coins</span>
							<span v-else-if="scope.row.transactionType == '4'">Withdraw</span>
							<span v-else-if="scope.row.transactionType == '5'">Top-up</span>
							<span v-else-if="scope.row.transactionType == '6'">Spending</span>
							<span v-else-if="scope.row.transactionType == '7'">Shift to</span>
							<span v-else-if="scope.row.transactionType == '9'">服务费</span>
							<span v-else-if="scope.row.transactionType == '10'">充值银行</span>
							<span v-else-if="scope.row.transactionType == '11'">充值加密货币</span>
							<span v-else-if="scope.row.transactionType == '12'">托管</span>
							<span v-else-if="scope.row.transactionType == '13'">托管赎回</span>
							<span v-else-if="scope.row.transactionType == '14'">直推奖励</span>
							<span v-else-if="scope.row.transactionType == '15'">平级奖励</span>
							<span v-else-if="scope.row.transactionType == '16'">奖金分红</span>
							<span v-else-if="scope.row.transactionType == '18'">贷款保证金</span>
							<span v-else-if="scope.row.transactionType == '19'">贷款</span>
							<span v-else-if="scope.row.transactionType == '20'">还款</span>
							<span v-else-if="scope.row.transactionType == '21'">退还保证金</span>
							<span v-else-if="scope.row.transactionType == '22'">复利收益</span>
							<span v-else-if="scope.row.transactionType == '23'">托管收益</span>
							<span v-else-if="scope.row.transactionType == '24'">团队奖励</span>
							<span v-else-if="scope.row.transactionType == '25'">越级奖励</span>
							<span v-else-if="scope.row.transactionType == '26'">闪兑</span>
							<span v-else-if="scope.row.transactionType == '27'">手续费分红</span>
							<span v-else-if="scope.row.transactionType == '28'">生态池分红</span>
							<span v-else-if="scope.row.transactionType == '28'">节点赠送</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="coinName" label="Tokens" show-overflow-tooltip></el-table-column>
				<el-table-column prop="balance" label="Amount" show-overflow-tooltip></el-table-column>
				<el-table-column prop="coinName" label="Status">
					<template #default="scope">
						<div v-if="scope.row.businessStatus == '0'" class="info-color">
							Awaiting processing
						</div>
						<div v-else-if="scope.row.businessStatus == '1'" class="success-color">
							Processed successfully
						</div>
						<div v-else class="danger-color">
							Processing failed
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="datesStr" label="Transaction Time" show-overflow-tooltip></el-table-column>
				<el-table-column label="Action">
					<template #default="scope">
						<el-button @click="handledetail(scope.row)" type="text" style="color: #786234;"
							size="small">Detail</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<NElPagination layout="prev, pager, next" :total="total" v-model:currentPage="page" background
				v-model:page-size="pageSize" @current-change="getBalanceCoin" class="transaction-pager"
				style="justify-content: flex-end;margin-top: 20px;" v-if="total > 0" </NElPagination>
				<!-- <div class="noData" v-if="list.length == 0&&!tableLoading">
						暂无数据
					</div> -->
		</div>
		<!-- 二维码预览 -->
		<el-dialog title="" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<div style="text-align: center">
				<vue-qr v-if="codeText" :size="250" :text="codeText" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确定</el-button>
			</span>
		</el-dialog>
		<!-- 详情 -->
		<el-dialog v-model="visibleDetail" title="Detail" width="30%" style="border-radius: 12px;"
			:before-close="onCloseDetail">
			<el-descriptions class="change-descriptions" title="" :column="1">
				<el-descriptions-item label="Bank ID:">{{ detailData.id }}</el-descriptions-item>
				<el-descriptions-item l label="Requets ID:">{{ detailData.requestId }}</el-descriptions-item>
				<el-descriptions-item label="Transaction ID:">{{ detailData.businessId }}</el-descriptions-item>
				<el-descriptions-item label="Withdrawal Address:">{{ detailData.walletAddress }}</el-descriptions-item>
				<el-descriptions-item label="Transaction Amount:" content-class-name="common-color"><span>{{
					detailData.balance }}</span></el-descriptions-item>
				<el-descriptions-item label="Deposit Address:">{{ detailData.sourceWalletAddress
					}}</el-descriptions-item>
				<el-descriptions-item label="Memo:" content-class-name="common-color"><span>{{ detailData.memo
						}}</span></el-descriptions-item>
				<el-descriptions-item label="Transaction fees:"><span>{{ detailData.gas }}</span></el-descriptions-item>
				<el-descriptions-item label="BankTransaction fees:" content-class-name="common-color"><span>{{
					detailData.amount }}</span></el-descriptions-item>
				<el-descriptions-item label="Transaction Time:">{{ detailData.datesStr }}</el-descriptions-item>
				<el-descriptions-item label="Transaction Status:"
					:content-class-name="detailData.businessStatus == '0' ? 'info-color' : detailData.businessStatus == '1' ? 'success-color' : 'danger-color'">
					{{ detailData.businessStatus == '0' ? 'Awaiting processing' : detailData.businessStatus == '1'
					? 'Processed successfully' : 'Processing failed' }}
				</el-descriptions-item>
			</el-descriptions>
		</el-dialog>
		<!-- 发送接收弹窗 -->
		<SendDiaolg v-if="sendVisible" v-model="sendVisible" :sendDatalsit="sendDatalsit" :sendType="sendType"
			@visibleEvent="visibleEvent" />
		<!-- 添加钱包 -->
		<AddMoneyDiaolg v-if="addMoneyVisible" v-model="addMoneyVisible" @visibleEvent="visibleEvent" />
		<!-- 买和卖 -->
		<BuyDiaolg v-if="buyVisible" v-model="buyVisible" @visibleEvent="visibleEvent" />
		<!-- 转账 -->
		<TransferDiaolg v-if="transfervisible" v-model="transfervisible" @visibleEvent="visibleEvent" />
		<!-- 充值弹窗 -->
		<!-- <RechargeDiaolg v-if="rechargeVisible" v-model="rechargeVisible" @visibleEvent="visibleEvent" /> -->
	</MainContentComp>
</template>

	<script>
	import { ElPagination as NElPagination } from 'element-plus';
	import {
		coinNames,
		getBalanceCoinNam,
		userBankBalance,
		totalUsdtBalance,
		accountGetBalance,
		coinLink,
	} from "@/api/index";
	import MainContentComp from '@/components/section/mainComp.vue'
	import SendDiaolg from "./components/sendDiaolg";
	import AddMoneyDiaolg from "./components/addMoneyDiaolg";
	import BuyDiaolg from "./components/buyDiaolg";
	import TransferDiaolg from "./components/transfer"
	// import RechargeDiaolg from "./components/rechargeDiaolg";
	import local from "@/utils/local";
	import {
		getBalanceCoinName
	} from "@/api/account.js";
	import {
		cryptoStatement
	} from "@/api/accountManage.js";
	import {
		cryptoCoinList
	} from "@/api/wallet.js";
	import VueQr from "vue-qr";
	export default {
		name: 'currencyEncryption',
		components: {
			MainContentComp,
			SendDiaolg,
			AddMoneyDiaolg,
			BuyDiaolg,
			VueQr,
			TransferDiaolg,
			NElPagination
		},
		props: {
			msg: String
		},
		computed: {
			// currentPath: {
			//   get() {
			//     console.log("this.$route.path: " + this.$route.path)
			//     return this.$route.path; // 获取当前路由路径
			//   }
			// }
		},
		watch: {
			// '$route': { // $route可以用引号，也可以不用引号  监听的对象
			//     handler(to, from) {
			//         this.currentPath = to.path
			//         const pathList = [
			//             '/transactions',
			//             '/Setting'
			//         ]
			//         // console.log("watch router: ", this.currentPath)
			//         if (pathList.indexOf(this.currentPath) > -1) {
			//             this.getUserBankBalance()
			//         }
			//     },
			//     deep: true, // 深度观察监听 设置为 true
			//     immediate: true, // 第一次初始化渲染就可以监听到
			// }
		},
		created() {
			this.getBalanceCoin();
			// this.getBankBalance()
			this.getData();
		},
		mounted() { },
		data() {
			return {
				balanceCoin: "0",
				headerImg: require("@/assets/img/ico_jiamibizhong@2x.png"),
				list: [
					// {
					//                "businessId": "db1e8369-ef1f-46bc-8f21-29ea337ac524",
					//                "targetBaseUserId": "0",
					//                "walletAddress": "TPdWr36nk9AzaYBfLAPkeB8EMwtLT9uUN7",
					//                "sourceWalletAddress": "TPdWr36nk9AzaYBfLAPkeB8EMwtLT9uUN7",
					//                "hash": "",
					//                "bankTransactionId": "c661ec3d114b775f558698b32498d099",
					//                "transactionType": "11",
					//                "coinName": "USDT_TRC",
					//                "coinLink": "TRON_USDT",
					//                "coinLogo": "https://api.mglobalcard.com/client_side/sys/file/truepath/coin/trc_usdt.png",
					//                "balance": "10.00",
					//                "balanceNoCharge": null,
					//                "datesStr": "2024-05-15 14:42:45",
					//                "businessStatus": 1,
					//                "remark": null,
					//                "id": "8478990018041643008",
					//                "requestId": "db1e8369-ef1f-46bc-8f21-29ea337ac524",
					//                "bankCharge": "0.00",
					//                "total": null,
					//                "lastAmount": null,
					//                "rate": "1",
					//                "block": "1",
					//                "blockTotal": "27",
					//                "desCoinName": null
					//            },
				],
				tableLoading: false,
				sendVisible: false,
				addMoneyVisible: false,
				BalanceCoinNam: "0",
				BalanceCoinNam1: "0",
				buyVisible: false,
				dialogVisible: false,
				rechargeVisible: false,
				tableActive: '1',
				coinName: "",
				pageSize: 10,
				codeText: "",
				page: 1,
				total: 0,
				option: [],
				choiceCoin: {
					coinName: '',
					coinLogo: "",
					id: '',
				},
				UsdtBalance: "0",
				bankBalance: "0",
				transfervisible: false,
				visibleDetail: false,
				sendType: 'first',
				sendData: '',
				detailData: '',
				globalLoading: null,
				aa: '',
				listCoin: []
			}
		},
		methods: {
			getBalanceCoin() {
				totalUsdtBalance().then((res) => {
					if (res.code == 200) {
						this.balanceCoin = res.data.amount;
					} else {
						this.$message.error(res.msg);
					}

				});
			},
			checkImg(item) {
				this.dialogVisible = true;
				this.codeText = item;
			},
			handleClose() {
				this.dialogVisible = false;
			},
			// changeType(data) {
			//   console.log(data)
			//   let obj = this.option.find(item => item.id === data)

			//   this.choiceCoin.coinName = obj.coinName;
			//   this.choiceCoin.coinLogo = obj.coinLogo;
			//   this.choiceCoin.id = obj.id;
			//   local.set("coinName", this.choiceCoin);
			//   this.globalLoading = this.$loading({
			//     lock: true,
			//     text: '',
			//     spinner: '',
			//     background: 'rgba(255, 255, 255, 0.8)'
			//   });
			//   this.getAccountBalanceCoin(obj, 1);
			// },
			onCopy(text) {
				if (navigator.clipboard) {
					// clipboard api 复制
					navigator.clipboard.writeText(text);
				} else {
					const textarea = document.createElement("textarea");
					document.body.appendChild(textarea);
					// 隐藏此输入框
					textarea.style.position = "fixed";
					textarea.style.clip = "rect(0 0 0 0)";
					textarea.style.top = "10px";
					// 赋值
					textarea.value = text;
					// 选中
					textarea.select();
					// 复制
					document.execCommand("copy", true);
					// 移除输入框
					document.body.removeChild(textarea);
				}
				this.$message.success("The content copied to clipboard");
			},
			changeTable(type) {
				this.list = []
				this.listCoin = []
				this.tableActive = type
				if (type == '1') {
					this.getData()
				} else {
					this.getcryptoStatement()
				}
			},
			changeData() {
				// this.page = 0
				if (this.tableActive == '1') {
					this.getData()
				} else {
					this.getcryptoStatement()
				}
			},
			getData() {
				const params = {
					pageSize: this.pageSize,
					page: this.page,
					baseUserId: "",
				};
				this.globalLoading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				cryptoCoinList(params).then((res) => {
					if (res.code == 200) {
						this.listCoin = res.data.records;
						this.total = res.data.total;
						this.globalLoading.close()
					} else {
						this.$message.error(res.msg);
					}

				});
			},
			async getcryptoStatement() {
				let params = {
					page: this.page,
					pageSize: this.pageSize,
				};
				this.globalLoading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				let res = await cryptoStatement(params);
				if (res.code == 200) {
					this.list = res.data.records;
					this.globalLoading.close()
					this.total = res.data.total;
				}

			},
			visibleEvent() {
				this.sendVisible = false
				this.addMoneyVisible = false;
				this.buyVisible = false;
				this.rechargeVisible = false;
				// this.getData();
				this.getcryptoStatement()
			},
			//type add:添加钱包 buy:买&卖 send:发送&接收
			handleClickBtn(type, row, type1) {
				if (type == "add") {
					this.addMoneyVisible = true;
				} else if (type == "buy") {
					this.buyVisible = true;
				} else if (type == "send") {
					let data = JSON.parse(JSON.stringify(row))
					this.sendType = type1
					this.sendDatalsit = data
					this.sendVisible = true;
				} else if (type == 'recharge') {
					this.rechargeVisible = true;
				}
			},
			handleClickBtnFasong(row, type1) {
				let data = JSON.parse(JSON.stringify(row))
				this.sendType = type1
				this.sendDatalsit = data
				this.sendVisible = true;
			},
			getAccountBalanceCoin(val, type) {
				if (type == "1") {
					let params = {
						coinName: val.coinName,
						coinLink: val.coinLink,
					}
					accountGetBalance(params).then((res) => {
						if (res.code == 200) {
							this.BalanceCoinNam = res.data.amount;
							this.globalLoading.close()
						} else {
							this.$message.error(res.msg);
						}

					});
				}
			},
			async getTableData() {
				let res = await coinNames();
				this.option = res.data
				this.choiceCoin.coinName = this.option[0].coinName;
				this.choiceCoin.coinLogo = this.option[0].coinLogo;
				this.choiceCoin.id = this.option[0].id;
				local.set("coinName", this.choiceCoin);
				this.getAccountBalanceCoin(this.option[0], 1);
			},
			getBankBalance(val) {
				this.getUserBankBalance();
				this.getTotalUsdtBalance();
			},
			getUserBankBalance() {
				userBankBalance().then((res) => {
					if (res.code == 200) {
						this.bankBalance = res.data.amount;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			getTotalUsdtBalance() {
				totalUsdtBalance().then((res) => {
					if (res.code == 200) {
						this.UsdtBalance = res.data.amount;
					} else {
						this.$message.error(res.msg)
					}
				});
			},
			transfer() {
				this.transfervisible = true;
			},
			handledetail(data) {
				this.detailData = JSON.parse(JSON.stringify(data))
				console.log('1231323', data)
				this.visibleDetail = true
			},
			onCloseDetail() {
				this.visibleDetail = false
			},
		}
	}
</script>

	<style lang="scss" scoped>
	@import '@/assets/css/main.css';

	.disal {
		display: flex;
		align-items: center;
	}

	.pointer {
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		line-height: 21px;
		margin-right: 20px;
		-webkit-background-clip: text;

	}

	.info-color {
		color: #909399 !important;
	}

	.success-color {
		color: #67c23a !important;
	}

	.danger-color {
		color: #f56c6c !important;
	}

	.default-check label {
		position: relative;
		cursor: pointer;
		padding: 0;
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		color: #acaec8;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.default-check label {
		position: relative;
		cursor: pointer;
		padding: 0;
		font-weight: 400;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: -0.5px;
		color: #eda858;
		display: flex;
		align-items: center;
	}

	.default-check label a {
		color: #3246d3;
		display: inline-block;
		margin: 0 4px;
	}

	// .title {
	// 		font-size: 26px;
	// 		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	// 		font-weight: bold;
	// 		color: #31363e;
	// 		margin-bottom: 20px;
	// 		text-align: right;
	// 	}

	.top-header {
		padding: 10px 0 10px 0;
		display: flex;
		align-items: center;
		margin: 0 auto;
		width: 60%;
	}

	.header-left {
		width: 30%;
		display: flex;
		align-items: center;
	}

	.header-left img {
		width: 18%;
	}

	.header-left-title {
		font-size: 16px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #31363E;
		line-height: 16px;
		-webkit-background-clip: text;
		margin-left: 10px;
	}

	.header-left-num {
		font-size: 24px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: bold;
		color: #31363E;
		line-height: 16px;


	}

	.list {
		margin: 0 auto;
		width: 60%;
	}

	.list .list-item-title {
		font-size: 18px !important;
		font-weight: bold !important;
		color: #31363E !important;
		margin-bottom: 8px;
		margin-top: 6px;
	}

	.header-left-num span {
		font-weight: 400;
		font-size: 13px;
	}

	:deep(.el-table .cell.el-tooltip .flex) {
		display: flex;
		align-items: flex-start;
	}

	:deep(.el-descriptions__label) {
		width: 150px;
		display: inline-table;
	}

	.changeBottom {
		width: 26%;
		height: 66px;
		background: #F7F6F5;
		border: 1px solid #F7F6F5;
		border-radius: 17px;
		font-size: 16px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: 400;
		color: #353a42;
	}

	.btn-list {
		flex: 1;
		text-align: right;
	}

	// 	.list {
	// 		margin-top: 20px;
	// 		min-height: 300px;

	// 		.list-item-title {
	// 			font-size: 18px !important;
	// 			font-weight: bold !important;
	// 			color: #31363E !important;
	// 			margin-bottom: 8px;
	// 			margin-top: 6px;
	// 		}

	// 		.list-item-num {
	// 			font-size: 14px;
	// 			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	// 			font-weight: 400;
	// 			line-height: 21px;
	// 			-webkit-background-clip: text;
	// 		}

	// 		.list-item-num1 {
	// 			font-size: 14px;
	// 		}

	// 		.noData {
	// 			text-align: center;
	// 			line-height: 60px;
	// 			color: #909399;
	// 		}
	// 	}
	// 	::v-deep {
	// 		.select-item {
	// 			margin-left: 10px;
	// 		}
	// 	}
	@media (max-width: 768px) {
		.top-header {
			display: block;
			width: 90%;
		}

		.header-left {
			width: 100%;
		}

		.changeBottom {
			width: 47%;
		}

		.btn-list {
			margin-top: 20px;
		}

		.list {
			width: 90%;
		}

		:deep(.el-dialog) {
			width: 90% !important;
		}

		.mobelCopy {
			white-space: normal;
			overflow-wrap: break-word;
			width: 65%;
			display: block;
		}

		:deep(.el-popper) {
			inset: 298.667px auto auto 28px;
		}

		:deep(.wallet-table) {
			.el-table__header-wrapper{
				display: none;
			}
			.el-table__body {
				width: 100% !important;
			}
			.el-table__row {
				display: flex;
				flex-direction: column;
				border: 1px solid #dedede;
				border-radius: 12px;
				// justify-content: flex-end;
				margin-bottom: 12px;
			}
			colgroup{
				display: none;
			}
			.cell{
				width: 100% !important;
			}
		}
	}
</style>