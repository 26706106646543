/**
 * ajax工具函数
 */
import axios from "axios";
// import { Message } from "element-ui";
import local from "@/utils/local";
import router from "@/router"; // 引入路由 自动找到index.js
import JSEncrypt from "jsencrypt";

// //后端给的公钥
// const publicKeyStr =
//   "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCEDu4KADff8wYpZBJ8O4PzPLoOO23M+MJg3RwOuihh1z39utQsaIDhheW05MbjSmo/kRWBm39iLKXth2MugcsBY7o1D+QRmjzr0ztY6sPwFIyr6uHXIcqj/QjFDkG34CTxvZGNek94g4tUG1kbmlUMRmC+wntIsNJVl4mkeqGR6QIDAQAB";
// //创建jsencrypt加密对象
// const jsRsa = new JSEncrypt();
// //设置公钥
// jsRsa.setPublicKey(publicKeyStr);
// //使用加密对象给密码加密也可以对用户名等加密
// let Rsapassword = jsRsa.encrypt(
//   `328登录，P8J5SqvqRv0icxaK，${new Date().getTime()}`
// );
// console.log("Rsapassword=====", Rsapassword);
// console.log("new Date().getTime()=====", new Date().getTime());
const bb = new JSEncrypt({ default_key_size: 1024 });
const rsaUtil = {
  //RSA 位数，这里要跟后端对应
  bits: 1024,

  //当前JSEncrypted对象
  thisKeyPair: {},

  //生成密钥对(公钥和私钥)
  genKeyPair: function (bits = rsaUtil.bits) {
    let genKeyPair = {};

    // rsaUtil.thisKeyPair = new JSEncrypt({ default_key_size: bits });

    //获取私钥
    genKeyPair.privateKey = bb.getPrivateKey();

    //获取公钥
    genKeyPair.publicKey = bb.getPublicKey();

    return genKeyPair;
  },

  //公钥加密
  encrypt: function (plaintext, publicKey) {
    if (plaintext instanceof Object) {
      //1、JSON.stringify
      plaintext = JSON.stringify(plaintext);
    }
    publicKey && bb.setPublicKey(publicKey);
    return bb.encrypt(plaintext);
  },

  //私钥解密
  decrypt: function (ciphertext, privateKey) {
    privateKey && bb.setPrivateKey(privateKey);
    let decString = bb.decrypt(ciphertext);
    if (decString.charAt(0) === "{" || decString.charAt(0) === "[") {
      //JSON.parse
      decString = JSON.parse(decString);
    }
    return decString;
  },
};
// 设置统一的服务器地址
// axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? 'https://api.bankeracard.com/client_side' : 'https://api.bankeracard.com/client_side';
axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? 'https://api.mglobalcard.com/client_side' : 'https://api.mglobalcard.com/client_side';
axios.defaults.headers["Content-Type"] = "application/json";
// 请求拦截器 interceptors
axios.interceptors.request.use(
  (config) => {
    // 统一带令牌
    let token = local.get("tk");
    let language = JSON.parse(localStorage.getItem('language')) && JSON.parse(localStorage.getItem('language')).language ? JSON.parse(localStorage.getItem('language')).language : 'EN'
    config.headers.lang = language
    config.headers.platform = "web2"
    if (token) {
      const name = sessionStorage.getItem("loginName");
      const sign = rsaUtil.encrypt(
        `${name},P8J5SqvqRv0icxaK,${new Date().getTime()}`,
        sessionStorage.getItem("javaPublicKey")
      );
      // Authorization

      config.headers.clientUserToken = token;
      config.headers.manageSign = sign;
    }
    config.data = JSON.stringify(config.data);
    return config;
  },
  (err) => {
    return Promise.reject(err); // 让Promise状态从进行中 -> 失败
  }
);

// 响应拦截器 interceptors ( 接收到数据之前 )
axios.interceptors.response.use(
  (response) => {
    // 如果后端响应的数据 有 code 且 有 msg
    if (response.data.code !== undefined && response.data.msg !== undefined) {
      let { code, msg } = response.data; // 取出code和msg
      switch (code) {
        case 401:
          router.push({ path: "/login", replace: true });
          break;
      }
      // 统一弹窗
      //   if (code === 0) {
      //     Message({
      //       message: msg,
      //       type: "success",
      //     });
      //   } else if (code === 1) {
      //     Message({
      //       message: msg,
      //       type: "error",
      //     });
      //   }
    }

    return response.data;
  },
  (err) => {
    // console.log("err: " + JSON.stringify(err,null,'\t'))
    // 如果错误码是401 证明未授权 没有权限
    if (err.response.status === 401) {
      // 直接跳转到登录
      router.push({ path: "/login", replace: true });
    }

    return Promise.reject(err); // 让Promise状态从进行中 -> 失败
  }
);

// 暴露出去
export default axios;
