<template>
	<MainContentComp headerTitle="Get Card">
		<section class="cards getCard block">
			<div class="wrapper">
				<div class="step active" data-step="main" v-if="mainLog">
					<h2 class="content__title">Get a Card</h2>
					<div class="getCard-content">
						<div class="card">
							<div class="card__top">
								<div class="card__currency">
									<!-- <div class="card__chip">
										<img :src="require('@/assets/img/cards/chip.svg')" alt="">
									</div> -->
								</div>
							</div>
						</div>
						<div class="button" @click="getphysical" data-step="physical">Assign a physical card</div>
						<div class="button border" @click="getVirtual" data-step="virtual">Get a Virtual card</div>
						<!-- <a id="fetchfeehidden" class="button modal-openButton displayNone" data-modal="fetchfeeModal">Hidden</a> -->
					</div>
				</div>
				<div v-if="visitor" class="step" data-step="virtual">
					<h2 class="content__title">Your card will look like</h2>
					<div class="getCard-content">
						<div class="card">
							<div class="card__top">
								<div class="card__currency">
									<!-- <div class="card__chip">
										<img :src="require('@/assets/img/cards/chip.svg')" alt="">
									</div> -->
								</div>
							</div>
						</div>
						<div @click="confireVistor" class="button">Continue</div>
					</div>
					<p class="terms">By continuing you accept our <a target="_blank"
							href="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/Client-Config/T%26C+MM/MIMT%26Cs2021.pdf">Terms
							&amp; conditions</a></p>
				</div>

				<div v-if="physical" class="step" data-step="physical">
					<h2 class="content__title">Enter your data</h2>
					<div class="block">
						<div class="default-input item">
							<label>Proxy Number</label>
							<div class="input">
								<input type="text" v-model="ProxyNumber" placeholder="Enter Proxy Number"
									autocomplete="off" maxlength="20">
							</div>
						</div>
						<div class="default-input item">
							<label>Card last 4 digits</label>
							<div class="input">
								<input type="password" v-model="lastDigits" placeholder="Enter Last 4 Digits"
									maxlength="4" autocomplete="off">
							</div>
						</div>
						<div class="item-check default-check" style="display: flex;align-items: center;">
							<el-checkbox v-model="isChecked"></el-checkbox>
							<label for="styled-checkbox-1">Accept <a target="_blank"
									href="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/Client-Config/T%26C+MM/MIMT%26Cs2021.pdf">Terms
									&amp; conditions</a></label>
						</div>
						<div type="button" @click="ContinuePhysical" class="button">Continue</div>
						<!-- <a id="hiddencontinuebtmAssignPhysical" class="button modal-openButton displayNone" data-step="physical-2"></a> -->
					</div>
				</div>
				<div v-if="visitorMoney" class="step" data-step="physical-2">
					<h2 class="content__title">Your card will look like</h2>
					<div class="getCard-content">
						<div class="card">
							<div class="card__top">
								<div class="card__currency">
									<!-- <div class="card__chip">
										<img :src="require('@/assets/img/cards/chip.svg')" alt="">
									</div> -->
								</div>
							</div>
						</div>
						<div @click="ContinuePys" class="button" data-step="physical-3">Continue</div>
					</div>
					<p class="terms">By continuing you accept our <a target="_blank"
							href="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/Client-Config/T%26C+MM/MIMT%26Cs2021.pdf">Terms
							&amp; conditions</a></p>
				</div>
				<!--  <div class="step" data-step="physical-3"> -->
				<div class="step" v-if="cardPin">
					<h2 class="content__title">Set your card PIN</h2>
					<div class="cards">
						<div class="card">
							<div class="card__top">
								<div class="card__currency">
									<div class="card__chip">
										<img :src="require('@/assets/img/cards/chip.svg')" alt="">
									</div>
								</div>
							</div>
						</div>
						<div class="card-pin card">
							<div class="inputs pinCode">
								<input type="text" v-model="one" name="n1" placeholder="1" maxlength="1"
									class="input-number"
									onkeypress="return (event.charCode >= 48 &amp;&amp; event.charCode <= 57 &amp;&amp; /^\d{0,3}$/.test(this.value));">
								<input type="text" v-model="two" name="n2" placeholder="2" maxlength="1"
									class="input-number"
									onkeypress="return (event.charCode >= 48 &amp;&amp; event.charCode <= 57 &amp;&amp; /^\d{0,3}$/.test(this.value));">
								<input type="text" v-model="three" name="n3" placeholder="3" maxlength="1"
									class="input-number"
									onkeypress="return (event.charCode >= 48 &amp;&amp; event.charCode <= 57 &amp;&amp; /^\d{0,3}$/.test(this.value));">
								<input type="text" v-model="four" name="n4" placeholder="4" maxlength="1"
									class="input-number"
									onkeypress="return (event.charCode >= 48 &amp;&amp; event.charCode <= 57 &amp;&amp; /^\d{0,3}$/.test(this.value));">
							</div>
							<div @click="savePin" type="button" class="button">Save PIN</div>
						</div>
					</div>
				</div>
				<el-dialog class="mobilPhone" style="border-radius: 20px;" title="Do you agree?" v-model="dialogVisible"
					width="30%">
					<p style="font-size: 15px;font-weight: bold;">Below mentioned amount will be charged as platform fee
					</p>
					<template #footer>
						<div class="cryptoAccountModal-bottom">
							<div class="default-input item">
								<input type="text" disabled value="0.00 $" placeholder="Enter the six digit code here"
									id="fetchfeeid" readonly="" required="">
							</div>
							<div type="button" class="button" @click="confirePay">Yes</div>
							<div type="button" class="button" @click="dialogVisible = false">NO</div>
							<div class="info">
								<!-- <p class="name">BTC Balance</p>
					                                   <p class="bet">0.000003284 BTC â‰ˆ Â£0.41</p> -->
							</div>
						</div>
					</template>
				</el-dialog>
				<el-dialog class="mobilPhone" style="border-radius: 20px;" title="Do you agree?"
					v-model="dialogVisibleVisitor" width="30%">
					<p style="font-size: 15px;font-weight: bold;">Below mentioned amount will be charged as platform fee
					</p>
					<template #footer>
						<div class="cryptoAccountModal-bottom">
							<div class="default-input item">
								<input type="text" disabled value="1.00 $" placeholder="Enter the six digit code here"
									id="fetchfeeid" readonly="" required="">
							</div>
							<div type="button" class="button" @click="confireVisitor">Yes</div>
							<div type="button" class="button" @click="dialogVisibleVisitor = false">NO</div>
							<div class="info">
								<!-- <p class="name">BTC Balance</p>
					                                   <p class="bet">0.000003284 BTC â‰ˆ Â£0.41</p> -->
							</div>
						</div>
					</template>
				</el-dialog>
			</div>
		</section>
	</MainContentComp>
</template>

<script>
import {
	ElMessage
} from 'element-plus';
import {
	assignPCard,
	setPin,
	getVCard
} from '@/api/card'
import MainContentComp from '@/components/section/mainComp.vue'
export default {
	name: 'FeeTable',
	components: {
		MainContentComp
	},
	props: {
		msg: String
	},
	computed: {
		assetsInfo() {
			return this.$store.state.assets
		}
	},
	data() {
		return {
			physical: false,
			visitor: false,
			visitorMoney: false,
			cardPin: false,
			mainLog: true,
			dialogVisible: false,
			dialogVisibleVisitor: false,
			ProxyNumber: '',
			lastDigits: '',
			isChecked: false,
			one: '',
			two: '',
			three: '',
			four: '',
			cardList: {}
		}
	},
	methods: {
		getphysical() {
			this.physical = true
			this.mainLog = false
		},
		ContinuePhysical() {
			if (!this.ProxyNumber) {
				ElMessage.error('Please enter the Proxy Number!')
				// this.$pu.showLoading()
				return
			}
			if (!this.lastDigits) {
				ElMessage.error('Please enter the Last Four Digits!')
				// this.$pu.showLoading()
				return
			}
			if (!(/^\d{4}$/.test(this.lastDigits))) {
				ElMessage.error('Invalid Last Four Digits!')
				// this.$pu.showLoading()
				return
			}
			if (!this.isChecked) {
				ElMessage.error('please agree to Terms and Condition of this site')
				return
			}
			this.dialogVisible = true
		},
		getVirtual() {
			this.visitor = true
			this.mainLog = false
		},
		ContinuePys() {
			let data = {
				physicalCardNo: this.ProxyNumber,
				last4: this.lastDigits
			}
			this.$pu.showLoading()
			assignPCard(data).then(res => {
				this.$pu.hideLoading()
				if (res.code == 200) {
					ElMessage.success({
						message: res.msg,
						type: 'success',
					})
					this.cardPin = true
					this.cardList = res.data
				} else {
					this.mainLog = true
					this.visitorMoney = false
					ElMessage.error(res.msg)
				}
			})
		},
		confirePay() {
			this.visitorMoney = true
			this.dialogVisible = false
			this.physical = false
		},
		confireVistor() {
			this.dialogVisibleVisitor = true
		},
		confireVisitor() {
			if (this.assetsInfo.total < 1) {
				ElMessage.error('Insufficient account balance')
				return
			}
			this.$pu.showLoading()
			getVCard().then(res => {
				this.$pu.hideLoading()
				if (res.code == 200) {
					ElMessage.success({
						message: res.msg,
						type: 'success',
					})
					setTimeout(() => {
						this.$router.push('/manageCards')
					}, 1000);
					// this.$router.push('/manageCards');
				} else {
					this.dialogVisibleVisitor = false
					this.mainLog = true
					this.visitor = false
					ElMessage.error(res.msg)
				}
			})
		},
		savePin() {
			var pin = this.one + this.two + this.three + this.four
			let data = {
				pin: pin,
				id: this.cardList.id
			}
			setPin(data).then(res => {
				if (res.code == 200) {
					ElMessage.success({
						message: res.msg,
						type: 'success',
					})
					setTimeout(() => {
						this.$router.push('/manageCards')
					}, 1000);
					// this.$router.push('/manageCards');
				} else {
					this.mainLog = true
					this.cardPin = false
					ElMessage.error(res.msg)
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.css';
</style>
<style lang="scss" scoped>
@media (max-width: 480px) {
	:deep(.el-dialog) {
		width: 90%;
	}
}

.button {
	cursor: pointer;
}

:deep(.default-input .input) {
	border: none;
	padding: 0;
}

:deep(.default-check label) {
	color: #acaec8;
}

:deep(.default-check label a) {
	color: #3246d3;
	display: inline-block;
	margin: 0 4px;
}

:deep(.el-checkbox) {
	margin-right: 5px;
}

.default-check label:before {
	display: none;
}
</style>