<template>
	<MainContentComp headerTitle="Transfer">
		<section class="transfers block getCard">
			<!-- 				<div class="messageBlock active" data-message="activeCard">
                    <span class="icon"><img src="resources/Onboarding/img/cards/success.svg" alt=""></span>
                    <p class="text">Your transfer has been confirmed</p>
                </div>
 -->
			<div class="wrapper">
				<h2 class="content__title transfers__title">How much do you want to send?</h2>
				<div class="transfers-send">
					<form action="#">
						<p class="description" id="damount">Your balance right now is {{ assetsInfo.userBankBalance }}
							USD</p>
						<div class="amount">
							<input style="width: 85%;" type="text" placeholder="Amount" v-model="amount"
								autocomplete="off" min="0" step="0.01" required="">
							<div class="cur">
								<el-dropdown trigger="click">
									<div style="display: flex;align-items: center;">
										<p> {{ coinName }}</p>
										<img :src="coinLogo" style="width: 20px;margin-left: 5px;">
									</div>
									<template #dropdown>
										<el-dropdown-menu v-for="(item, index) in coinList" :key="index">
											<el-dropdown-item @click="handleCommand(item)">
												<img :src="item.coinLogo" style="width: 20px;" alt="">
												{{ item.coinName }}
											</el-dropdown-item>
										</el-dropdown-menu>
									</template>
								</el-dropdown>
							</div>
						</div>
						<div class="amount">
							<input type="text" placeholder="Description" v-model="remark" autocomplete="off"
								required="">
						</div>
						<div class="info" style="padding: 5px 25px;">
							<div class="info-title" style="width: 100%;">
								<!-- <div class="icon" id="demo"></div> -->
								<el-select v-model="email" clearable filterable placeholder="Select">
									<el-option v-for="item in contentList" :key="item.email" :label="item.email"
										:value="item.email">
									</el-option>
								</el-select>
							</div>
							<a class="add" id="userAccountSelect" @click="toPage('/addP2PTransferRecipient')">
								<svg width="50" height="50" viewBox="0 0 50 50" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<circle cx="25" cy="25" r="16" fill="#3246D3"></circle>
									<mask id="mask0_520_20624" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="9"
										y="9" width="32" height="32">
										<circle cx="25" cy="25" r="16" fill="#3246D3"></circle>
									</mask>
									<g mask="url(#mask0_520_20624)">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M15.9599 19.734L17.2865 19.9041C17.6277 19.9539 17.9653 19.7925 18.1434 19.4944C18.6334 18.6421 19.5817 18.1694 20.5499 18.295L21.1299 18.3698C21.4272 18.4072 21.7221 18.2836 21.9057 18.0445L22.4168 17.375C22.791 16.8876 22.8079 16.2112 22.4585 15.7054L21.3565 14.1006C20.9603 13.5256 20.4454 13.0442 19.8471 12.6896C19.668 12.5838 19.4951 12.4677 19.3291 12.3419L17.3892 10.8691C16.036 11.6112 14.7962 12.5469 13.7082 13.6472C11.1722 16.1934 9.5673 19.5333 9.15625 23.1203C10.3657 22.8902 11.5875 23.4543 12.2062 24.5283C12.3168 24.7196 12.4541 24.8937 12.6138 25.0455L13.7686 26.1429C13.9356 26.3013 14.1385 26.416 14.3595 26.477L14.4943 26.5142C15.4018 26.7666 15.0109 25.6752 14.4137 24.7305L13.5295 23.3208C13.0495 22.5557 13.0645 21.5759 13.5677 20.8261C14.0956 20.0337 15.0219 19.6108 15.9599 19.734Z"
											fill="#5368FB"></path>
										<path
											d="M35.1993 12.6875L34.74 13.2911C34.7318 13.3018 34.723 13.3121 34.7134 13.3218C33.6907 14.3704 32.7899 15.5188 32.0263 16.7475L31.3982 17.7589C31.3012 17.9288 31.3264 18.1375 31.4615 18.2825C31.6452 18.4802 31.8998 18.6071 32.1767 18.6391L32.4803 18.6742C33.7995 18.8275 34.8991 19.6981 35.286 20.8955C35.6591 22.057 35.2809 23.3168 34.3172 24.1223L31.3254 26.6171C31.1084 26.8027 30.9871 27.0675 30.9927 27.3435V28.0057C30.9864 28.2134 31.0948 28.4093 31.2793 28.5232C32.0984 29.0206 32.6289 29.8451 32.7179 30.759L32.7881 31.4122C32.8129 31.6347 33.0162 31.8012 33.2537 31.7935H35.0096C35.7649 31.7999 36.4521 31.3833 36.7588 30.7333L36.8643 30.5227C37.3538 29.4618 38.4441 28.7575 39.6714 28.7094L40.0841 28.6918C40.117 28.6903 40.1508 28.688 40.1844 28.6849L40.4375 28.6609C40.812 27.3344 41.0012 25.9675 41.0004 24.5949C41.0125 20.4271 39.2534 16.4279 36.1151 13.4888C35.8188 13.2099 35.5135 12.9428 35.1993 12.6875Z"
											fill="#5368FB"></path>
									</g>
									<circle cx="24.9416" cy="20.2941" r="3.29412" fill="white"></circle>
									<ellipse cx="24.9416" cy="29.2354" rx="7.05882" ry="3.76471" fill="#FFA720">
									</ellipse>
									<circle cx="37.8438" cy="37.6891" r="7.1735" fill="#FFA720" stroke="white"
										stroke-width="2"></circle>
									<path d="M35.46 37.6875H40.2273" stroke="white" stroke-width="1.5"
										stroke-linecap="round"></path>
									<path d="M37.8436 35.3047L37.8436 40.0721" stroke="white" stroke-width="1.5"
										stroke-linecap="round"></path>
								</svg>
							</a>
							<!-- <a href="/transferRecipientP2P" class="change">Change it</a> -->
						</div>
						<div class="bottom">
							<!-- 								<a href="#" class=" note modal-openButton" data-modal="createTransfer">
									<img src="resources/Onboarding/img/transfers/note.svg" alt="">
								</a>
 -->
							<div type="button" class="button" @click="Confirm">Confirm</div>
							<a id="cryptohidden" class="button modal-openButton displayNone"
								data-modal="cryptoAccountModal">Continue</a>
							<a id="fetchfeehidden" class="button modal-openButton displayNone"
								data-modal="fetchfeeModal">Hidden</a>
						</div>

					</form>
				</div>
				<el-dialog class="mobilPhone" style="border-radius: 20px;" title="Do you agree?" v-model="dialogVisible"
					width="30%">
					<p style="font-size: 15px;font-weight: bold;">Below mentioned amount will be charged as platform fee
					</p>
					<template #footer>
						<div class="cryptoAccountModal-bottom">
							<div class="default-input item">
								<input type="text" disabled value="0.00 $" placeholder="Enter the six digit code here"
									id="fetchfeeid" readonly="" required="">
							</div>
							<div type="button" class="button" @click="confirePay">Yes</div>
							<div type="button" class="button" @click="dialogVisible = false">NO</div>
							<div class="info">
								<!-- <p class="name">BTC Balance</p>
					                                   <p class="bet">0.000003284 BTC â‰ˆ Â£0.41</p> -->
							</div>
						</div>
					</template>
				</el-dialog>
			</div>
			<div class="modal cryptoAccountModal active" data-modal="ActiveCardModal2FA" v-if="isCode">
				<div class="modal-block">
					<div class="modal-top">
						<div class="tabs">
							<h1 class="modal-title">
								<!-- <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			                                    <path class="svg__gray" fill-rule="evenodd" clip-rule="evenodd" d="M18.3545 8.70021C16.202 8.70021 14.457 10.4452 14.457 12.5977C14.457 13.0672 14.0764 13.4478 13.6069 13.4478C13.1374 13.4478 12.7568 13.0672 12.7568 12.5977C12.7568 9.50618 15.263 7 18.3545 7C21.4461 7 23.9522 9.50618 23.9522 12.5977C23.9522 13.0672 23.5716 13.4478 23.1021 13.4478C22.6326 13.4478 22.252 13.0672 22.252 12.5977C22.252 10.4452 20.5071 8.70021 18.3545 8.70021Z" fill="#FFA720"/>
			                                    <path class="svg__black" d="M9.26758 15.8777C9.4067 14.4296 9.47626 13.7056 9.80303 13.1583C10.0908 12.6763 10.5153 12.2907 11.0226 12.0503C11.5986 11.7773 12.326 11.7773 13.7807 11.7773H22.9275C24.3822 11.7773 25.1096 11.7773 25.6856 12.0503C26.1929 12.2907 26.6174 12.6763 26.9052 13.1583C27.2319 13.7056 27.3015 14.4296 27.4406 15.8777L28.4586 26.4734C28.624 28.1954 28.7067 29.0564 28.4222 29.7216C28.1722 30.306 27.7331 30.7893 27.1753 31.094C26.5404 31.4409 25.6754 31.4409 23.9455 31.4409H12.7627C11.0328 31.4409 10.1678 31.4409 9.53288 31.094C8.97511 30.7893 8.53597 30.306 8.28601 29.7216C8.00147 29.0564 8.08419 28.1954 8.24962 26.4734L9.26758 15.8777Z" fill="#3246D3"/>
			                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3542 19.0986C19.9654 19.0986 21.2715 17.7925 21.2715 16.1814C21.2715 15.7119 21.6521 15.3313 22.1216 15.3313C22.5911 15.3313 22.9717 15.7119 22.9717 16.1814C22.9717 18.7315 20.9044 20.7988 18.3542 20.7988C15.8041 20.7988 13.7368 18.7315 13.7368 16.1814C13.7368 15.7119 14.1174 15.3313 14.5869 15.3313C15.0564 15.3313 15.437 15.7119 15.437 16.1814C15.437 17.7925 16.7431 19.0986 18.3542 19.0986Z" fill="white"/>
			                                    <circle class="svg__gray" cx="29" cy="29" r="7" fill="#FFA720" stroke="#EFF6FF" stroke-width="2"/>
			                                    <path d="M26.6992 29H31.3006" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
			                                    <path d="M29 26.6992L29 31.3006" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
			                                </svg> -->

								Authentication
							</h1>

						</div>
						<div class="modal-close buttonClose" @click="isCode = false">
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M1 11L11 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
								<path d="M11 11L1 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
							</svg>
						</div>
					</div>
					<div class="cryptoAccountModal-main active">
						<div class="cryptoAccountModal-top">
							<p style="font-size:15px;"><strong>Please enter the 2FA code from your virtual 2FA
									device</strong></p>
							<!-- <input type="text"> -->
							<!-- <p>0Ã‚Â£</p> -->

							<div class="info">
								<!-- <div class="change">
			                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			                                        <path d="M8.38608 15.6146V5.38542C8.38608 4.62027 7.7658 4 7.00066 4C6.23551 4 5.61523 4.62027 5.61523 5.38542V15.6146C5.61523 16.3797 6.23551 17 7.00066 17C7.7658 17 8.38608 16.3797 8.38608 15.6146Z" fill="#1A1B26"/>
			                                        <path d="M15.6148 7.885L15.6148 18.115C15.6148 18.8799 16.2349 19.5 16.9998 19.5C17.7647 19.5 18.3848 18.8799 18.3848 18.115L18.3848 7.885C18.3848 7.12009 17.7647 6.5 16.9998 6.5C16.2349 6.5 15.6148 7.12009 15.6148 7.885Z" fill="#1A1B26"/>
			                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.41421 13.5C2.52331 13.5 2.07714 14.5771 2.70711 15.2071L6.29289 18.7929C6.68342 19.1834 7.31658 19.1834 7.70711 18.7929L11.2929 15.2071C11.9229 14.5771 11.4767 13.5 10.5858 13.5H3.41421Z" fill="#1A1B26"/>
			                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5858 10C21.4767 10 21.9229 8.92286 21.2929 8.29289L17.7071 4.70711C17.3166 4.31658 16.6834 4.31658 16.2929 4.70711L12.7071 8.29289C12.0771 8.92286 12.5233 10 13.4142 10L20.5858 10Z" fill="#1A1B26"/>
			                                    </svg>
			                                </div> -->
								<!-- <div class="cur"><img src="img/cryptoAccount/btc.svg" alt=""></div> -->
							</div>
						</div>
						<div class="cryptoAccountModal-bottom">
							<div class="default-input item">
								<input type="text" v-model="googleCode" placeholder="Enter the six digit code here"
									autocomplete="off" maxlength="6" required="">
							</div>
							<button type="button" @click="SubmitCode" class="button">Submit</button>
							<div class="info">
								<!-- <p class="name">BTC Balance</p>
			                                <p class="bet">0.000003284 BTC Ã¢Â‰Âˆ Ã‚Â£0.41</p> -->
							</div>
						</div>
					</div>
				</div>

			</div>
		</section>
	</MainContentComp>
</template>

<script>
import {
	bankTransfer,
	contactList,
	coinNames
} from '@/api/transaction'
import MainContentComp from '@/components/section/mainComp.vue'
export default {
	name: 'FeeTable',
	components: {
		MainContentComp
	},
	props: {
		msg: String
	},
	data() {
		return {
			amount: '',
			remark: '',
			email: '',
			googleCode: '',
			contentList: [],
			coinList: [{
				coinName: 'USD',
				coinLogo: 'https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/flags/flag/USD.svg'
			}],
			coinName: 'USD',
			coinLogo: 'https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/flags/flag/USD.svg',
			dialogVisible: false,
			isCode: false
		}
	},
	computed: {
		assetsInfo() {
			return this.$store.state.assets
		}
	},
	mounted() {
		if (this.$route.query.email) {
			this.email = this.$route.query.email
		}
		this.contactList()
		// this.getCoinNames()
	},
	methods: {
		toPage(path) {
			this.$router.push(path)
		},
		contactList() {
			this.$pu.showLoading()
			contactList().then(res => {
				this.$pu.hideLoading()
				this.contentList = res.data
			})
		},
		handleCommand(command) {
			let coinListPrxy = JSON.parse(JSON.stringify(command))
			this.coinName = coinListPrxy.coinName
			this.coinLogo = coinListPrxy.coinLogo
		},
		getCoinNames() {
			coinNames().then(res => {
				this.coinList = res.data
			})
		},
		confirePay() {
			this.googleCode = ''
			this.dialogVisible = false
			this.isCode = true
		},
		SubmitCode() {
			this.$pu.showLoading()
			let data = {
				amount: this.amount,
				remark: this.remark,
				email: this.email,
				googleCode: this.googleCode
			}
			bankTransfer(data).then(res => {
				this.$pu.hideLoading()
				if (res.code == 200) {
					this.isCode = false
					this.googleCode = ''
					this.$message.success(res.msg)
					setTimeout(() => {
						this.$router.push('/transactions')
					}, 1000);
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Confirm() {
			if (!this.amount) {
				this.$message.error('Please enter the Amount!');
				return
			}
			if (!this.email) {
				this.$message.error('Please select the contact!');
				return
			}
			// if (this.amount > this.assetsInfo.userBankBalance) {			
			if (parseFloat(this.amount) > parseFloat(this.assetsInfo.userBankBalance)) {
				this.$message.error('Insufficient account balance')
				return
			}
			this.dialogVisible = true
		},
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.css';
@import '@/assets/css/transfer.css';

.default-selectSearch+.select2-container--default .select2-selection--single {
	padding: 21px 83px 30px 84px;
}

.button {
	cursor: pointer;
}

@media (max-width: 480px) {
	:deep(.el-dialog) {
		width: 90%;
	}
}
</style>