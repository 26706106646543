import request from "@/utils/request";
import Store from '@/store/index'
export const getNational = () =>
    request({
      url: "/stylopay/userinfo/getNational",
      method: "GET",
    });
// export const getNational = () =>{
//     return new Promise((resolve,reject)=>{
//         request({
//             url: "/stylopay/userinfo/getNational",
//             method: "get"
//         }).then(res=>{
//             if (res.code === 200) {
//                 console.log('res: ' + JSON.stringify(res.data,null,'\t'))
//                 res.data.map(item => {
//                     item.logo = 'https://api.mglobalcard.com/client_side' + item.logo
//                 })
//             }
//             resolve(res) 
//         }).catch(e=>{
//             reject(e)
//         })
//     })
// }
    