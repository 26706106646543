import request from "@/utils/request";
import Store from '@/store/index'
//登录
export const login = (data) => {
  return new Promise((resolve, reject) => {
    request({
      url: "/l/login",
      method: "post",
      data,
    }).then(res => {
      if (res.code === 200) {
        const userInfo = {
          logo: res.data.logo,
          name: res.data.userName,
          email: res.data.email,
        }
        Store.commit('set_userInfo', userInfo)
      }
      resolve(res)
    })
  })
};
//注册
export const registerBank = (data) =>
  request({
    url: "/l/register",
    method: "post",
    data,
  });
//退出登录
export const loginOut = (parpams) =>
  request({
    url: "/l/logout",
    method: "get",
    parpams,
  });
// 获取国家alpha2代码
export const getNational = () =>
  request({
    url: "/stylopay/userinfo/getNational",
    method: "GET",
  });
// export const getNational = () =>{
//   return new Promise((resolve,reject)=>{
//       request({
//           url: "/stylopay/userinfo/getNational",
//           method: "get"
//       }).then(res=>{
//           if (res.code === 200) {
//               console.log('res: ' + JSON.stringify(res.data,null,'\t'))
//               res.data.map(item => {
//                   item.logo = 'https://api.mglobalcard.com/client_side' + item.logo
//               })
//           }
//           resolve(res) 
//       }).catch(e=>{
//           reject(e)
//       })
//   })
// }
// 发送邮箱验证码
export const sendEmailCode = (data) =>
  request({
    url: "/code/sendEmailCode",
    method: "post",
    data,
  });
// 重置密码
export const resetPassword = (data) =>
  request({
    url: "/l/resetPassword",
    method: "post",
    data,
  });
// 添加或更新个人信息
export const addUserInfo = (data) =>
  request({
    url: "/stylopay/userinfo/addUserInfo",
    method: "post",
    data,
  });
  //
export const getIsOpenSecond = (data) =>
  request({
    url: "/l/isOpenSecond",
    method: "post",
    data,
  });