import IndexComp from '@/components/FeeTable.vue'
import LoginComp from '@/components/login.vue'
import SignUpComp from '@/components/signUp.vue'
import ForgotPasswordComp from '@/components/ForgotPassword.vue'
import RecipientsComp from '@/components/RecipientsComp.vue'
import AddRecipientComp from '@/components/AddRecipientComp.vue'
import TransactionsComp from '@/components/TransactionsComp.vue'
import AccountDashboardComp from '@/components/AccountDashboardComp.vue'
import AccountDetailsComp from '@/components/AccountDetailsComp.vue'
import ManageCardsComp from '@/components/manageCardsComp.vue'
import GetCardComp from '@/components/GetCardComp.vue'
import SettingComp from '@/components/SettingComp.vue'
import TransferComp from '@/components/TransferComp.vue'
import AddAccountComp from '@/components/AddAccount.vue'
import RegistrationComp from '@/components/user/registration.vue'
import ProfileComp from '@/components/user/ProfileComp.vue'
import dashboardComsp from '@/components/user/dashboardComsp.vue'
import currencyEncryption from '@/components/currencyEncryption/index.vue'
import { createRouter, createWebHistory } from 'vue-router'
import local from "@/utils/local";

const routes = [
  {
    path: '/',
    component: AccountDashboardComp,
    meta: {
      // title: this.$t('')
    }
  },
  {
    path: '/login',
    component: LoginComp,
    meta: {
      // title: this.$t('')
    }
  },
  {
    path: '/signUp',
    component: SignUpComp,
    meta: {
      // title: this.$t('')
    }
  },
  {
    path: '/accountDashboard',
    component: AccountDashboardComp,
    meta: {
      title: 'Accounts'
    }
  },
  {
    path: '/accountDetails',
    component: AccountDetailsComp,
    meta: {
      title: 'Account Details'
    }
  },
  {
    path: '/manageCards',
    component: ManageCardsComp,
    meta: {
      title: 'ManageCards'
    }
  },
  {
    path: '/getCard',
    component: GetCardComp,
    meta: {
      title: 'Get Card'
    }
  },
  {
    path: '/recipients',
    component: RecipientsComp,
    meta: {
      title: 'Recipients'
    }
  },
  {
    path: '/addP2PTransferRecipient',
    component: AddRecipientComp,
    meta: {
      title: 'Add Recipient'
    }
  },
  {
    path: '/currencyEncryption',
    component: currencyEncryption,
    meta: {
      title: 'currencyEncryption'
    }
  },
  {
    path: '/transferP2Pmm',
    component: TransferComp,
    meta: {
      title: 'Transfer'
    }
  },
  {
    path: '/transactions',
    component: TransactionsComp,
    meta: {
      title: 'Transactions'
    }
  },
  {
    path: '/feemodule',
    component: IndexComp,
    meta: {
      // title: this.$t('')
    }
  },
  {
    path: '/Setting',
    component: SettingComp,
    meta: {
      title: 'Setting'
    }
  },
  {
    path: '/forgotPassword',
    component: ForgotPasswordComp,
    meta: {
      // title: this.$t('')
    }
  },
  {
    path: '/profile',
    component: ProfileComp,
    meta: {
      title: 'Profile'
    }
  },
  {
    path: '/registration',
    component: RegistrationComp,
    meta: {
      title: 'Registration'
    }
  },
  {
    path: '/dashboard',
    component: dashboardComsp,
    meta: {
      title: 'dashboard'
    }
  },
  {
    path: '/addAccount',
    component: AddAccountComp,
    meta: {
      title: 'Add Account'
    }
  },
  
  //submitOnboardingDetails
  //addAccount
]


const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHistory(),
  mode: "hash",
  routes, // `routes: routes` 的缩写
})

const whiteList = [
  '/login',
  '/signup',
  '/forgotPassword',
]
router.beforeEach((to, from, next) => {
  // console.log('router.beforeEach to ' + to.path)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let token = localStorage.getItem('tk');
  if (!token && whiteList.indexOf(to.path) == -1) {
    return next('/login')
  } else {
    if(to.path == '/'){
      return next('/accountDashboard')
    }else{
      
    return next()
    }
  }
  // if (to.path === '/login') return next();

  // if (!localStorage.getItem('walletAddress') || !localStorage.getItem('accessToken')) {
  //   return next('/login')
  // } else {
  //   next()
  // }
})
export default router;